<template>
  <tbb-card title="Booked Service Details">
    <b-card class="mt-2">
      <b-list-group flush>
        <b-list-group-item
          v-for="(service, index) in savedAppointmentStore.response.services"
          :key="index"
          class="d-flex justify-content-between"
        >
          <div>
            <span class="font-weight-bolder">{{ service.name }}</span> <span class="text-muted text-nowrap">({{ service.duration }} min)</span>
          </div>
          <span>${{ service.price | price }}</span>
        </b-list-group-item>
      </b-list-group>
      <div class="total padding-list-group-item font-weight-bold d-flex justify-content-between">
        <span>Total</span>
        <span class="text-right font-weight-bolder">${{ savedAppointmentStore.response.services | sumPrice }}</span>
      </div>
    </b-card>
  </tbb-card>
</template>

<script>
import {
  BListGroup, BListGroupItem, VBTooltip, BCard,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import { STYLIST_PAGE } from '@/router/routes/routes-names'
import TbbCard from '../../sites/TbbCard.vue'

export default {
  filters: {
    sumPrice(selectedServices) {
      let price = 0
      selectedServices.forEach(service => {
        price += +service.price
      })
      return price.toFixed(2)
    },
    price(price) {
      return Number(price).toFixed(2)
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BListGroup,
    BListGroupItem,
    BCard,
    TbbCard,
  },
  data() {
    return {
      stylistPagePath: STYLIST_PAGE,
    }
  },
  computed: {
    ...mapState('BookingServiceStoreModule', {
      savedAppointmentStore: state => state.saveAppointment,
    }),
  },
}
</script>
<style lang="scss" scoped>
.list-group-item{
  padding-left: 0;
  padding-right: 0;
  &:hover{
    background-color: #fff;
  }
}
.padding-list-group-item {
  padding: 0.75rem 0;
}
.total {
  border-top: 1px solid #d1b08f;
}
.remove-service {
  color: red;
}
</style>
