<template>
  <div
    id="user-profile"
  >
    <stylist-page-header :header-data="header" />
    <!-- Booking -->
    <section
      id="booking-service"
      class="pb-2 py-md-4 px-sm-2 px-lg-4"
    >
      <b-container>
        <b-row class="mr-0 ml-0">
          <b-col
            lg="3"
            order-lg="2"
            class="second-column"
          >
            <booking-service-saved-appointments-details />
          </b-col>
          <b-col
            lg="9"
            class="booking-fields-box text-center"
          >
            <h1 class="thank-you-title">
              Thanks {{ savedAppointmentStore.response.customer.name }}, your booking was successful
            </h1>
            <div class="d-flex flex-column thank-you-details">
              <h5>Booking for: <span>{{ savedAppointmentStore.response.customer.name }} {{ savedAppointmentStore.response.customer.lastName }}</span></h5>
              <h5>Booking date: <span>{{ savedAppointmentStore.response.startsAt | dateUsLocale }} {{ savedAppointmentStore.response.startsAt | hourUsLocale }}</span></h5>
            </div>
            <div class="d-flex justify-content-center m-2">
              <button
                class="btn btn-primary"
                @click="backToStylistPage()"
              >
                Go back to {{ header.businessName }}'s homepage
              </button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!--/ Booking  -->
  </div>
</template>

<script>
import {
  BRow, BCol, BContainer,
} from 'bootstrap-vue'
import StylistPageHeader from '@/components/stylist/stylist-page/StylistPageHeader.vue'
import { mapActions, mapState } from 'vuex'

import BookingServiceSavedAppointmentsDetails from '@/components/booking/booking-service/BookingServiceSavedAppointmentsDetails.vue'
import moment from 'moment'
import { STYLIST_PAGE } from '@/router/routes/routes-names'

/* eslint-disable global-require */
export default {
  filters: {
    dateUsLocale(date) {
      return moment(date, 'YYYY-MM-DD HH:mm').format('MMMM D, yyyy')
    },
    hourUsLocale(hour) {
      return moment(`${hour}`, 'YYYY-MM-DD H:mm').format('h:mm a')
    },
  },
  components: {
    BRow,
    BCol,
    BContainer,
    StylistPageHeader,
    BookingServiceSavedAppointmentsDetails,
  },
  data() {
    return {
      header: {
        avatar: require('@/assets/images/user-uploads/stylist-profile/avatars/avatar-s-2.jpg'),
        businessName: null,
        coverImg: require('@/assets/images/user-uploads/stylist-profile/backgrounds/timeline.jpg'),
      },
    }
  },
  computed: {
    ...mapState('StylistPageStoreModule', {
      stylistStore: state => state.stylist,
    }),
    ...mapState('BookingServiceStoreModule', {
      savedAppointmentStore: state => state.saveAppointment,
    }),
  },
  watch: {
    stylistStore: {
      deep: true,
      handler(newStylistValue) {
        if (newStylistValue.response) {
          this.header.businessName = newStylistValue.response.businessName
        }
      },
    },
  },
  mounted() {
    this.getHeaderData()
    this.clearSelectedDateTime()
    this.clearSelectedServices()
  },
  methods: {
    ...mapActions('BookingServiceStoreModule', [
      'clearSelectedDateTime',
      'clearSelectedServices',
    ]),
    ...mapActions('StylistPageStoreModule', [
      'fetchPublicStylist',
    ]),
    getHeaderData() {
      if (!this.stylistStore.response.businessName) {
        this.fetchPublicStylist(this.$store.getters['app/getSubdomain'])
      }
      this.header.businessName = this.stylistStore.response.businessName
    },
    backToStylistPage() {
      this.$router.push({ name: STYLIST_PAGE })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
@media (max-width: 991px) {
  .booking-fields-box {
    margin-top: 2rem;
  }
}
</style>
<style lang="scss" scoped>
.thank-you-title{
  color: #aaa;
  font-weight: 300;
  font-size: 2.5rem;
  @media (max-width: 767.98px){
    font-size: 1.75rem;
  }
}
.thank-you-details{
  h5{
    font-weight: 400;
    margin: 1rem 0 0;
  }
  span{
    font-weight: 600;
  }
}
</style>
